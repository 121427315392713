import { render, staticRenderFns } from "./CargueMasivo.vue?vue&type=template&id=67082f12&scoped=true&"
import script from "./CargueMasivo.vue?vue&type=script&lang=js&"
export * from "./CargueMasivo.vue?vue&type=script&lang=js&"
import style0 from "./CargueMasivo.vue?vue&type=style&index=0&id=67082f12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67082f12",
  null
  
)

export default component.exports