<template>
    <div class="container">
        <h2>Administrar semáforo</h2>
        <div class="card col-xs-12 col-md-6">
            <h5>{{ accion }}</h5>
            <form>
                <div class="col">
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Nombre:</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            v-model="nombre" />
                    </div>
                </div>
                <div class="col">
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Tiempo de respuesta en horas:</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            v-model="tiempo" />
                    </div>
                </div>
                <div class="col">
                    <label for="exampleColorInput" class="form-label">Contrato:</label>
                    <select id="inputState1" class="form-select" v-model="contrato_select"
                        @change="contratoId(contrato_select)">
                        <option v-for="item, index in contratos" :key="index">
                            {{ item.nombre }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="exampleColorInput" class="form-label">Color:</label>
                    <input type="color" class="form-control form-control-color color" id="exampleColorInput" v-model="color"
                        title="Choose your color">
                </div>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Descripción:</label>
                    <div class="mb-3">
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                            v-model="descripcion"></textarea>
                    </div>
                </div>
                <button type="submit" class="btn btn-success" @click="save()">
                    Guardar
                </button>
                <button type="submit" class="btn btn-warning" @click="clear">
                    Limpiar
                </button>
                <button type="submit" class="btn btn-primary" @click="back">
                    Atrás
                </button>

            </form>
        </div>
        <Tabla :datos="datos" :tabla="tabla" :endpoint="endpoint" @response="response" @clear="clear" />
    </div>
</template>
<script>
import axios from 'axios'
import Tabla from './Tabla.vue';
export default {
    components: {
        Tabla
    },
    mixins: [],
    props: {

    },
    data() {
        return {
            URL_API: process.env.VUE_APP_URL_API,
            nombre: '',
            descripcion: '',
            accion: 'Insertar semaforo',
            contratos: [],
            contrato_id: '',
            contrato_select: '',
            color: '#563d7c',
            tiempo: '',
            datos: [],
            endpoint: 'semaforo',
            massiveUpdate: false,
            campos: '',
            listas: [],
            semaforo: {},
            cantidad: 10,
            currentUrl: '',
            urlEndPoint: 'semaforo',
            tabla: [
                { nombre: "#", orden: "DESC", calculado: 'false' },
                { nombre: "Nombre", orden: "DESC", tipo: "texto", calculado: 'false' },
                { nombre: "Tiempo respuesta", orden: "DESC", tipo: "texto", calculado: 'false' },
                { nombre: "Contrato", orden: "DESC", tipo: "texto", calculado: 'false' },
                { nombre: "Descripcion", orden: "DESC", tipo: "texto", calculado: 'true' },
                { nombre: "Código de color", orden: "DESC", tipo: "texto", calculado: 'false' },
                { nombre: "Color", orden: "DESC", tipo: "texto", calculado: 'false' },
            ],
            checks: [],
            idItem: '',

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    created() {
        this.getContratos()
        this.getItems()
    },
    methods: {
        getItems() {
            let self = this;
            let urlEndPoint = ''
            urlEndPoint = self.URL_API + "api/v1/semaforo/" + self.cantidad
            if (this.currentUrl != '') {
                urlEndPoint = this.currentUrl
            }
            let config = this.configHeader();
            axios
                .get(urlEndPoint, config)
                .then(function (result) {
                    self.datos = result;
                });
        },
        response(response) {
            console.log(response)
            this.currentUrl = response.currentUrl
            this.nombre = response.nombre
            this.tiempo = response.tiempo
            this.contrato_select = response.contrato
            this.contratoId(response.contrato)
            this.color = response.color
            this.descripcion = response.descripcion
            this.idItem = response.id
            this.actualizar = true
            this.accion = "Editar semáforo";
            this.checks = []
        },
        check() { },
        save() {
            // if (this.checks.length > 0) { // validación para realizar actualización masiva
            //     this.massiveUpdate = !this.massiveUpdate
            //     this.campos.tipo_documento_identidad_id = this.tipo_documento_identidad_id
            //     this.campos.estado_empleado_id = this.estado_empleado_id
            //     this.campos.sig_cargo_id = this.sig_cargo_id
            // } else {
            let self = this;
            let urlEndPoint = ''
            if (self.actualizar) {
                urlEndPoint = self.URL_API + "api/v1/semaforo/" + self.idItem
            }
            else {
                urlEndPoint = self.URL_API + "api/v1/semaforo"
            }
            let semaforo = {
                nombre: this.nombre,
                tiempo: this.tiempo,
                contrato_id: this.contrato_id,
                color: this.color,
                descripcion: this.descripcion
            }
            let config = this.configHeader();
            axios
                .post(urlEndPoint, semaforo, config)
                .then(function (result) {
                    self.showAlert(result.data.message, result.data.status);
                    self.getItems();
                    self.clear()
                });
            // }
        },
        clear() {
            this.nombre =  ''
            this.tiempo =  ''
            this.contrato_id =  ''
            this.contrato_select = ''
            this.color =  '#563d7c'
            this.descripcion =  ''
            this.actualizar = false;
        },
        back() {

        },
        getContratos() {
            let config = this.configHeader();
            let self = this;
            axios.get(self.URL_API + "api/v1/contratoslista", config).then(function (result) {
                self.contratos = result.data;
            });
        },
        contratoId(contrato) {
            let self = this;
            var cont = 0;
            this.contratos.forEach(function (element) {
                if (contrato == element.nombre) {
                    self.contrato_id = element.id;
                    cont++;
                }
            });
            console.log(self.contrato_id)
            if (cont <= 0) {
                self.contrato_id = "";
            }
        },
        showAlert(mensaje, icono) {
            this.$swal({
                position: "top",
                icon: icono,
                title: mensaje,
                showConfirmButton: false,
                timer: 1500,
            });
        },
        configHeader() {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            return config;
        },
    }
};
</script>
<style scoped>
.card {
    margin: 40px 0px 40px 0px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

label {
    float: left;
}

.card button {
    margin: 15px;
}

h2 {
    font-family: "Montserrat", sans-serif;
    margin: 20px 0px 20px 0px;
}

select {
    margin-bottom: 20px;
}

.color {
    width: 100%;
}
</style>